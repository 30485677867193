		/**********   Placeholder block   **********/

			.placeholder_mixin{
				font-family: ProximaNova;
				color:  rgba(110, 110, 110, 0.65);
				font-size: 18px;/* Approximation due to font substitution */
				font-weight: 300;

			}

			& ::-webkit-input-placeholder {.placeholder_mixin}
			& ::-moz-placeholder {.placeholder_mixin}
			& :-ms-input-placeholder {.placeholder_mixin}
			& :-moz-placeholder {.placeholder_mixin}
			& ::placeholder {.placeholder_mixin}


			.form__group{
				position: relative;
				min-width: 70%;
				border-radius: 5px;
				border: 1px solid #acacac;
				padding-left: 25px;
				padding-right: 10px;
			}
			
			.form__input{
				.placeholder_mixin;
				color:  #383838;
				height: 65px;
				width: 100%;


				padding-top: 18px;
				padding-left: 8px;
				border: none;

				&:-webkit-autofill{
					background-color: #fff;
				}

				&:placeholder-shown{
					padding-top: 0;
					height: 66px;
					padding-left: 3px;
				}

				&:placeholder-shown + .form__label{
					display: none;
				}
			}		


			.form__label{
				.placeholder_mixin;
				font-size: 14px;
				font-weight: 300;
				position: absolute;
				top: 10px;
    			left: 16px;
    			text-transform: none;
			}


		/**********   ./Placeholder block   **********/