@import 'import/_services/_resolutions.less';
@import 'import/_services/_mixins.less';


/*---------------------------------------------------------------------------*
 * reset styles
 *---------------------------------------------------------------------------*/


@import 'import/_services/normalize.less';

:root{
    --accent_color: #7db9e8;
    --second_color:  #99c447;

}


@import 'import/_services/_reset.less';

/*---------------------------------------------------------------------------*
 * ./reset styles
 *---------------------------------------------------------------------------*/


/*---------------------------------------------------------------------------*
 * keyframes
 *---------------------------------------------------------------------------*/


/*@import 'import/_services/_keyframes.less';*/


/*---------------------------------------------------------------------------*
 * ./keyframes
 *---------------------------------------------------------------------------*/


/*---------------------------------------------------------------------------*
 * fonts
 *---------------------------------------------------------------------------*/


@import 'import/2018_04_fonts.less';


/*---------------------------------------------------------------------------*
 * ./fonts
 *---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*
 * mixins
 *---------------------------------------------------------------------------*/

.RobotoSlab{
    font-family: RobotoSlab;
}

.BebasNeueRegular{
    font-family: BebasNeueRegular;
}

.BebasNeueBold{
    font-family: BebasNeueBold;
}

.BebasNeue{
    font-family: BebasNeue;
}

@import 'import/_services/_mixins.less';
/*---------------------------------------------------------------------------*
 * ./mixins
 *---------------------------------------------------------------------------*/



/*---------------------------------------------------------------------------*
 * ./preloader
 *---------------------------------------------------------------------------*/



@import 'import/_services/_placeholder.less';


/*---------------------------------------------------------------------------*
 * ./preloader
 *---------------------------------------------------------------------------*/



 /*---------------------------------------------------------------------------*
 * button
 *---------------------------------------------------------------------------*/

.button{
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    cursor: pointer;
    font-family: BebasNeueRegular;
    padding-top: 5px;
    color:  #303030;
    font-size: 21px;
    font-weight: 400;
    line-height: 30.193px;
    letter-spacing: 3px;
    border: 2px solid transparent;
    transition: transform 0.1s,  background-color 0.3s;
    max-width: 354px;
    width: 100%;
    height: 61px;
    border-radius: 102px;

    background-image: url(/images/buttons/border.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media @tablet_landscape{
        font-size: 24px;
    }
    &:hover{
        background-image: url(/images/buttons/fill.png);
        color:  #ffffff;
    }

    &:focus,
    &:active{
        transform: scale(1.05);
        outline: none;
    }

}



 /*---------------------------------------------------------------------------*
 * ./button
 *---------------------------------------------------------------------------*/

 /*---------------------------------------------------------------------------*
 * template styles
 *---------------------------------------------------------------------------*/

 .common{
    &__header{
        font-family: BebasNeueBold;
        color:  #848484;
        font-weight: 400;
        line-height: 36.621px;
        letter-spacing: 2px;
        text-align: center;
        font-size: 24px;
        margin-bottom: 40px;

        @media @tablet{
            font-size: 36px;
            margin-bottom: 80px;

        }

        &::after{
            display: block;
            content: '';
            width: 106px;
            height: 2px;
            background-color: var(--second_color);
            margin: 18px auto 0;
        }
    }
 }

/*@import 'import/_template/header.less';*/
/*@import 'import/_template/footer.less';*/
/*@import 'import/_template/modal.less';*/
/*@import 'import/_template/menu.less';*/

.header{
    background-image: url(/images/backgrounds/header.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 46px 5%;

    @media @tablet{
       padding: 46px 30px 80px; 
    }

    &__wrapper{
        .wrapper;
        display: flex;
        flex-direction: column;
        justify-content: center;
    align-items: center;
    text-align: center;
    }

    &__links{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;

        @media @tablet{
            flex-direction: row;

        }

        &__bitrix{
            width: 203px;
            height: 36px;
            background-image: url(/images/logo/b24.png);
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 20px;

            @media @tablet{
                margin-bottom: 0;
            }
            
        }

        &__jetmix{
            width: 194px;
            height: 115px;
            background-image: url(/images/logo/jetmix.png);
            background-repeat: no-repeat;
            background-size: cover;
            margin-left: -30px;
            margin-bottom: 20px;

            @media @tablet{
                margin-bottom: 0;
            }
        }

        &__1c{
            width: 189px;
            height: 37px;
            background-image: url(/images/logo/bitrix.png);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__free{
        font-family: BebasNeue;
        color:  rgba(255, 255, 255, 0.34);
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 3px;
        margin-bottom: 30px;

        &::after{
            background-color:  rgba(255, 255, 255, 0.34);
            width: 90px;
            height: 2px;
            display: block;
            content: '';
            margin: 30px auto 0;
        }
    }

    &__name{
        font-family: RobotoSlab;
        color:  #ffffff;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.4;
        letter-spacing: 2px;
        margin-bottom: 30px;

        @media @tablet{
            
            font-size: 36px;
        }
    }

    &__rules{
        font-family: RobotoSlab;
        color:  #ffffff;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 42px;
        letter-spacing: 2px;

        @media @tablet{
            font-size: 30px;
        }
    }

    &__last{
        font-family: BebasNeueRegular;
        color:  rgba(255, 255, 255, 0.34);
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 3px;

        @media @tablet{
            font-size: 30px;
        }
    }
}

.footer{
    position: relative;
    padding: 45px 30px 48px;

    &__wrapper{
        .wrapper;
    }

    &__map{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

    }

    &__block{
        position: relative;
        z-index: 2;
        max-width: 416px;
        width: 100%;
        padding: 38px 50px 50px;
        background-color: rgba(0, 0, 0, 0.83);

        &__group{
            margin-bottom: 38px;

            &:last-child{
                margin-bottom: 0;
            }

        }

        &__header{
            margin-bottom: 6px;
            font-family: BebasNeueRegular;
            color:  #99c447;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 3px;

        }

        &__information{
            font-family: ProximaNova;
            color:  #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.6;
            margin-bottom: 5px;
            display: block;

            &:last_child{
                margin-bottom: 0;
            }
        }
    }
   
}

.modal{
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.53);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5 ;
    display: none;

    &.open{
        display: flex;
    }

    &__container{
        width: 90%;
        height: 90%;
        background-color: #fff;
        padding: 60px 10px 30px;
        position: relative;

        @media @tablet_landscape{
            padding: 50px 80px 40px;
        }

    }

    &__body{
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        & .gallery{
            &__img{
                width: 100%;
                max-width: initial;
                height: auto;

                & img{
                    object-fit: contain;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }


    &__close{
        max-width: 30px;
        max-height: 30px;
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;

        @media @tablet{
            right: 30px;
            top: 20px;

        }

        &__svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }

    }


}
/*---------------------------------------------------------------------------*
 * ./template styles
 *---------------------------------------------------------------------------*/



 /*---------------------------------------------------------------------------*
 * inclusive styles
 *---------------------------------------------------------------------------*/

.materials{
    padding: 40px 5% 10px;

    @media @tablet{
        padding: 82px 30px 40px;
    }

    &__wrapper{
        .wrapper;
    }

    &__container{
        display: grid;
        grid-gap: 65px;
        grid-template-columns: 1fr;

        @media @tablet{
            grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
        }
    }

    &__block{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @media @tablet{
            flex-direction: row;
            text-align: left;

        }


        &__img{
            border: 1px dashed #b8b8b8;
            width: 105px;
            height: 105px;
            display: flex;
            justify-content: center;
            align-items: center;
            

            @media @tablet{
                margin-right: 28px;
            }
        }

        &__presentation{
            width: 70%;
            height: 70%;
            background-image: url(/images/icons/presentation.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__container{
            max-width: 450px;
        }

        &__description{
            font-family: ProximaNova;
            color:  #383838;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 6px;

        }

        &__size{
            font-family: ProximaNova;
            color:  #a5a5a5;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 6px;

        }

        &__download{
            font-family: BebasNeueBold;
            color:  #81bacf;
            font-size: 20px;
            font-weight: 400;
            line-height: 36.621px;
            text-decoration: underline;

            letter-spacing: 1.6px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media @tablet{
                justify-content: flex-start;
            }

            &::before{
                width: 12px;
                height: 18px;
                content: url(/images/icons/download.png);
                margin-top: 6px;
                margin-right: 18px;
                display: flex;
                align-items: center;
            }
        }


    }
}

.gallery{
    padding: 40px 5%;

    @media @tablet{
        padding: 82px 30px 140px;
    }
    

    &__wrapper{
        .wrapper;
    }

    &__container{
        margin-bottom: 45px;
        display: grid;
        grid-gap: 58px;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

        @media @tablet{
            margin-bottom: 85px;

        }
    }

    &__img{
        width: 100%;
        max-width: 260px;
        height: 180px;
        background-color: #b8b8b8;
        margin: 0 auto;
        overflow: hidden;

        & img{
            object-fit: cover;
            object-position: center;
            height: 100%;

        }
    }

    & .button{
        margin: 0 auto;
    }
}

.late{
    height: 100vh;
    width: 100vw;
    background-image: url(/images/backgrounds/cap.png);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 20px;
    justify-content: center;

    @media @tablet_landscape{
        padding: 10vh 20px;
        justify-content: space-between;
    }

    &__logo{
        max-width: 155px;
        max-height: 151px;
        margin-bottom: 30px;

        @media @tablet{
            max-width: 200px;
            max-height: 151px;

        }

        @media @tablet_landscape{
            max-width: 255px;
            max-height: 151px;

        }

        &__img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

        }
    }

    &__header{
        text-align: center;
        font-family: BebasNeueRegular;
        color:  #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 30px;
        letter-spacing: 5px;

        @media @tablet{
            font-size: 32px;
        }

        @media @tablet_landscape{
            font-size: 60px;
        }
    }

    &__clock{
        max-width: 40px;
        max-height: 91px;
        object-fit: contain;
        object-position: center;

        @media @tablet_landscape{
            max-width: 91px;
            max-height: 91px;
        }
    }
}

/*---------------------------------------------------------------------------*
 * ./inclusive styles
 *---------------------------------------------------------------------------*/

