.wrapper{
    max-width: 1230px;
    margin: 0 auto;
    width: 100%;
}

.title{
	font-family: BebasNeueBold;
	color:  #383838;
	font-size: 27px;
	font-weight: 400;
	letter-spacing: 3px;
	text-align: center;

	@media @tablet_landscape{
		font-size: 36px;
	}

	&::after{
		display: block;
		content: '';
		background-color: var(--second_color);
		width: 106px;
		height: 2px;
		margin: 0 auto;
		margin-top: 14px;

		@media @tablet_landscape{
			margin-top: 16px;
		}

	}
}


.bold{
    font-weight: 700 !important;
}


.ttu{
	text-transform: uppercase;
}