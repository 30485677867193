/*---------------------------------------------------------------------------*
 * reset styles
 *---------------------------------------------------------------------------*/

*{
    box-sizing: border-box;
}

body{
    min-width: 320px !important;
    margin: 0;  

    &.no_scroll{
        overflow: hidden;
    }
}


h1, h2, h3, h4, h5, h6, p, ul{
    margin: 0;
    line-height: 1;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}




p, span{

}


ul{
    list-style: none;
    padding-left: 0px;
    position: relative;
}

/*li{

    &::before{
        display: block;
        border-radius: 50%;
        background-color:  #e4524a;
        width: 7px;
        min-width: 7px;
        height: 7px;
        min-height: 7px;
        margin-top: 10px;
        color: var(--accent_color);
        margin-left: 0;
        content: '';
    }
}*/

a, 
a:hover,
a:focus,
a:active,
a:focus:active{
    text-decoration: none;
}

details, summary{
    display: block;

    &:focus{
        outline: none;
    }
}

summary::-webkit-details-marker {
  display: none;
}

summary{
  cursor: pointer;
}



img {
    object-fit: contain;
}

input:focus{
    outline: none;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: rgb(255, 255, 255);
}

/*::-webkit-scrollbar-button {
background-image:url('');
background-repeat:no-repeat;
width:5px;
height:0px
}

::-webkit-scrollbar-track {
background-color:#1d1d1d;
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 0px;
border-radius: 0px;
background-color:#2d2d2d;
}

::-webkit-scrollbar-thumb:hover{
background-color:#2d2d2d;
}

::-webkit-resizer{
background-image:url('');
background-repeat:no-repeat;
width:4px;
height:0px
}

::-webkit-scrollbar{
width: 4px;
}*/


/*---------------------------------------------------------------------------*
 * ./reset styles
 *---------------------------------------------------------------------------*/