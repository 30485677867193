/*---------------------------------------------------------------------------*
 * BebasNeue
 *---------------------------------------------------------------------------*/

/** Thin */
@font-face {
  font-family: BebasNeue;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Thin.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Thin.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: BebasNeueThin;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Thin.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Thin.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}


/** Light */
@font-face {
  font-family: BebasNeue;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Light.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Light.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: BebasNeueLight;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Light.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Light.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}



/** Regular */
/** Normal */
@font-face {
  font-family: BebasNeue;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Regular.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: BebasNeueRegular;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Regular.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}



/** Bold */
@font-face {
  font-family: BebasNeue;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Bold.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Bold.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: BebasNeueBold;
  src: 
    url('/fonts/BebasNeue/BebasNeue-Bold.woff2') format('woff2'),
    url('/fonts/BebasNeue/BebasNeue-Bold.woff') format('woff'),
    url('/fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}



/*----- Others -----*/

/** Book*/
@font-face {
  font-family: BebasNeueBook;
  src:  
    url("BebasNeue/BebasNeue-Book.woff2") format("woff2"), 
    url("BebasNeue/BebasNeue-Book.woff") format("woff"), 
    url("BebasNeue/BebasNeue-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}



/*---------------------------------------------------------------------------*
 * end BebasNeue
 *---------------------------------------------------------------------------*/

 /*---------------------------------------------------------------------------*
 * RobotoSlab
 *---------------------------------------------------------------------------*/

/** Thin */
@font-face {
  font-family: RobotoSlab;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Thin.woff2') format('woff2'), 
    url('/fonts/RobotoSlab/RobotoSlab-Thin.woff') format('woff'),
    url('/fonts/RobotoSlab/RobotoSlab-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: RobotoSlabThin;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Thin.woff2') format('woff2'), 
    url('/fonts/RobotoSlab/RobotoSlab-Thin.woff') format('woff'), 
    url('/fonts/RobotoSlab/RobotoSlab-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}


/** Light */
@font-face {
  font-family: RobotoSlab;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Light.woff2') format('woff2'),
    url('/fonts/RobotoSlab/RobotoSlab-Light.woff') format('woff'),
    url('/fonts/RobotoSlab/RobotoSlab-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: RobotoSlabLight;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Light.woff2') format('woff2'), 
    url('/fonts/RobotoSlab/RobotoSlab-Light.woff') format('woff'), 
    url('/fonts/RobotoSlab/RobotoSlab-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}



/** Regular */
/** Normal */
@font-face {
  font-family: RobotoSlab;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Regular.woff2') format('woff2'),
    url('/fonts/RobotoSlab/RobotoSlab-Regular.woff') format('woff'),
    url('/fonts/RobotoSlab/RobotoSlab-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: RobotoSlabRegular;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Regular.woff2') format('woff2'), 
    url('/fonts/RobotoSlab/RobotoSlab-Regular.woff') format('woff'), 
    url('/fonts/RobotoSlab/RobotoSlab-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}


/** Bold */
@font-face {
  font-family: RobotoSlab;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Bold.woff2') format('woff2'),
    url('/fonts/RobotoSlab/RobotoSlab-Bold.woff') format('woff'),
    url('/fonts/RobotoSlab/RobotoSlab-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: RobotoSlabBold;
  src: 
    url('/fonts/RobotoSlab/RobotoSlab-Bold.woff2') format('woff2'), 
    url('/fonts/RobotoSlab/RobotoSlab-Bold.woff') format('woff'), 
    url('/fonts/RobotoSlab/RobotoSlab-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}



/*---------------------------------------------------------------------------*
 * end RobotoSlab
 *---------------------------------------------------------------------------*/





/*---------------------------------------------------------------------------*
 * ProximaNova
 *---------------------------------------------------------------------------*/

/** Thin */
@font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-Thin.woff2') format('woff2'), 
    url('/fonts/ProximaNova/ProximaNova-Thin.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: ProximaNovaThin;
  src: 
    url('/fonts/ProximaNova/ProximaNova-Thin.woff2') format('woff2'), 
    url('/fonts/ProximaNova/ProximaNova-Thin.woff') format('woff'), 
    url('/fonts/ProximaNova/ProximaNova-Thin.ttf') format('truetype'),;
  font-style: normal;
  font-weight: normal;
}

/** Thin Italic */
@font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-ThinItalic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-ThinItalic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-ThinItalic.ttf') format('truetype');
 font-weight: 100;
 font-style: italic;
} 

/** Light */
@font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-Light.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-Light.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: ProximaNovaLight;
  src: 
    url('/fonts/ProximaNova/ProximaNova-Light.woff2') format('woff2'), 
    url('/fonts/ProximaNova/ProximaNova-Light.woff') format('woff'), 
    url('/fonts/ProximaNova/ProximaNova-Light.ttf') format('truetype'),;
  font-style: normal;
  font-weight: normal;
}


/** Light Italic */
  @font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-LightItalic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-LightItalic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-LightItalic.ttf') format('truetype');
 font-weight: 300;
 font-style: italic;
}  

/** Regular */
/** Normal */
@font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-Regular.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: ProximaNovaRegular;
  src: 
    url('/fonts/ProximaNova/ProximaNova-Regular.woff2') format('woff2'), 
    url('/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'), 
    url('/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype'),;
  font-style: normal;
  font-weight: normal;
}

/** Regular Italic */
/** Normal Italic */
@font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-Italic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-Italic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
} 
@font-face {
  font-family: ProximaNovaItalic;
  src:
    url('/fonts/ProximaNova/ProximaNova-Italic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-Italic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
} 


/** Semibold */
/** Demi Bold */
@font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-SemiBold.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-SemiBold.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-SemiBold.ttf') format('truetype');
 font-weight: 600;
 font-style: normal;
}  

/** Semibold Italic */
/** Demi Bold Italic */
@font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-SemiBoldItalic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-SemiBoldItalic.ttf') format('truetype');
 font-weight: 600;
 font-style: italic;
}  

/** Bold */
@font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-Bold.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: ProximaNovaBold;
  src: 
    url('/fonts/ProximaNova/ProximaNova-Bold.woff2') format('woff2'), 
    url('/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'), 
    url('/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype'),;
  font-style: normal;
  font-weight: normal;
}

/** Bold Italic */
@font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-BoldItalic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-BoldItalic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-BoldItalic.ttf') format('truetype');
 font-weight: 700;
 font-style: italic;
}  

/** Extra Bold */
/** Ultra Bold */
@font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-ExtraBold.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-ExtraBold.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
} 

/** Extra Bold Italic*/
/** Ultra Bold Italic*/
@font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-ExtraBoldItalic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-ExtraBoldItalic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-ExtraBoldItalic.ttf') format('truetype');
 font-weight: 800;
 font-style: italic;
}  

/** Black */
/** Heavy */
 @font-face {
  font-family: ProximaNova;
  src:
    url('/fonts/ProximaNova/ProximaNova-Black.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-Black.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
} 


/** Black Italic*/
/** Heavy Italic*/
  @font-face {
 font-family: ProximaNova;
 src:
    url('/fonts/ProximaNova/ProximaNova-BlackItalic.woff2') format('woff2'),
    url('/fonts/ProximaNova/ProximaNova-BlackItalic.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-BlackItalic.ttf') format('truetype');
 font-weight: 900;
 font-style: italic;
}  


/*---------------------------------------------------------------------------*
 * end ProximaNova
 *---------------------------------------------------------------------------*/





